@import '../../../styles/color';

.user-info-root {
  height: 392px;
  margin-top: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 512px;
    width: 100%;
  }
  .profile-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    .col {
      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
      .row {
        &:nth-child(1) {
          display: flex;
          justify-content: flex-start;
          gap: 16px;
        }
        &:nth-child(2) {
          margin-top: 6px;
        }
        &:nth-child(3) {
          margin-top: 14px;
        }
      }
    }
    .user-img {
      width: 94px;
      height: 94px;
      border-radius: 47px;
    }

    .name {
      font-size: 22px;
      font-weight: bold;
    }

    .logout {
      font-size: 14px;
      color: $gray600;
      text-decoration: underline;
    }

    .email {
      font-size: 15px;
      color: $gray600;
    }

    .benefit {
      font-size: 14px;
      color: $gray600;
      width: 115px;
      height: 32px;
      border: 1px solid $gray300;
      border-radius: 4px;
    }
  }
  .coin-progress {
    margin-top: 50px;
    max-width: 512px;
    width: 100%;
    height: 107px;
    padding: 20px 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid $primary-blue;
    border-radius: 4px;
    background-color: rgba($color: $primary-blue, $alpha: 0.05);

    .text {
      font-weight: 700;
      .colored {
        color: $primary-blue;
      }
    }
    .progress-bar {
      width: 100%;
      height: 20px;
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 3px;

      .inner-bar {
        position: relative;

        display: flex;
        justify-content: flex-end;
        width: 30%;
        height: 14px;
        border-radius: 2px;
        background: linear-gradient(90deg, $primary-purple, $primary-blue);

        .cursor {
          position: absolute;
          top: -14px;
          right: -6px;
          polygon {
            fill: $primary-purple;
          }
        }
      }
    }
  }
}
