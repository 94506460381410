@import '../../../../styles/color';
@import '../../../../styles/mixin';

.search-select-root {
  position: relative;

  @include mobile {
    display: none;
  }

  .search-select-input {
    position: relative;
    max-width: 120px;
    min-width: 90px;
    height: 56px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 0;
    background-color: $white;
    cursor: pointer;

    .search-select-label {
      min-width: 65px;
      display: block;
      color: $black;
      flex: 1;
      font-size: 15px;
    }

    .search-select-under-arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &.focus {
      .search-select-label {
        color: $primary-blue;
      }
    }
  }

  .search-select-option {
    position: absolute;
    left: -16px;
    border-radius: 4px;
    background-color: $gray100;
    cursor: pointer;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    transition-duration: 300ms;
    transition-property: max-height;
    z-index: 999;
    margin-top: 4px;
    overflow: hidden;

    &.hide {
      max-height: 0px;
      overflow: hidden;
    }

    &.show {
      max-height: 1000px;
    }

    .search-select-option-item {
      width: 232px;
      height: 40px;
      padding: 11px 16px;
      display: flex;
      align-items: center;
      color: $gray600;
      font-size: 15px;

      &:hover {
        background: #e8e7fb;
        color: $black;
      }

      &.selected {
        color: $black;
      }
    }
  }
}
