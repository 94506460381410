@import '../../../styles/color';

.autosetting-popup-root {
  max-width: 420px;
  width: 100%;
  height: fit-content;
  border-radius: 4px;
  background-color: #fff;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    border-bottom: 1px solid $gray200;

    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .close-button {
      width: 32px;
      height: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    padding: 20px 20px 20px 32px;

    .item {
      margin-bottom: 16px;
      .label {
        font-size: 16px;
        color: $primary-blue;
        margin-bottom: 8px;
      }
      input {
        padding: 8px 10px;
        border: 1px solid $gray300;
        border-radius: 3px;
        font-size: 16;
        font-weight: 500;
      }
      .text {
        margin-left: 8px;
        color: $black;
      }
    }

    .download {
      margin-top: 10px;
      width: 100%;
      height: 48px;
      background-color: $primary-blue;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      border-radius: 4px;
    }
  }
}
