@import '../../../styles/color';

.download-root {
  padding: 0px 140px;
  .title {
    margin-top: 56px;
    font-size: 24px;
    font-weight: bold;
  }
  .divider {
    margin-top: 24px;
    width: 100%;
    height: 2px;
    background-color: #191919;
  }
  .content {
    margin-top: 16px;

    .text {
      color: $gray600;
      text-align: center;
      width: 100%;
      padding: 100px 0px;
    }
  }
}
