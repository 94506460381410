#root .music-root .main-section {
  padding: 56px 140px 120px;
}
#root .music-root .main-section .new-section .play-container {
  width: 87.5vw;
  border-top: 2px solid black;
}
@media (max-width: 1024px) {
  #root .music-root .main-section {
    padding-left: 80px;
  }
}
@media (max-width: 720px) {
  #root .music-root .main-section {
    padding-left: 32px;
  }
}
@media (max-width: 720px) {
  #root .music-root .main-section .new-section .play-container {
    width: 85vw;
  }
}/*# sourceMappingURL=music.styles.css.map */