.payment-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 80px 200px 80px;
}
.payment-root .section {
  max-width: 1640px;
  width: 100%;
}
.payment-root .section:nth-child(1) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 20px;
}
.payment-root .section:nth-child(2) {
  margin-top: 90px;
}
.payment-root .box {
  height: 320px;
  padding: 30px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
}
.payment-root .box.first {
  display: flex;
  align-items: center;
  grid-column: 1/5;
}
.payment-root .box.first .divider {
  width: 1px;
  height: 220px;
  background-color: #dbdbdb;
}
.payment-root .box.first .row {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  flex-grow: 1;
}
.payment-root .box.first .row .text {
  font-size: 24px;
  font-weight: 700;
  color: #767676;
}
.payment-root .box.first .row .coin {
  font-size: 32px;
  font-weight: 700;
  color: #111111;
}
.payment-root .box.first .row .history {
  width: 84px;
  height: 32px;
  border-radius: 16px;
  background-color: #ebebeb;
  color: #767676;
  font-size: 14px;
}
.payment-root .box.second {
  grid-column: 1/2;
  position: relative;
}
.payment-root .box.second .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.payment-root .box.second .header .text {
  font-size: 20px;
  font-weight: 700;
}
.payment-root .box.second .header button {
  width: 56px;
  height: 32px;
  border-radius: 16px;
  background-color: #ebebeb;
  color: #767676;
  font-size: 14px;
}
.payment-root .box.second .content {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.payment-root .box.second .content .item {
  display: flex;
  flex-direction: column;
}
.payment-root .box.second .content .item .text {
  font-size: 14px;
  color: #767676;
}
.payment-root .box.second .content .item .coin {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #111111;
}
.payment-root .box.second .arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  right: 30px;
  bottom: 30px;
}
.payment-root .box.third {
  grid-column: 2/3;
}
.payment-root .box.third .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.payment-root .box.third .header .text {
  font-size: 20px;
  font-weight: 700;
}
.payment-root .box.third .header .benefit {
  font-size: 14px;
  color: #767676;
  width: 115px;
  height: 32px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
.payment-root .box.third .content {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
}
.payment-root .box.third .content .item {
  display: flex;
  flex-direction: column;
}
.payment-root .box.third .content .item .text {
  font-size: 14px;
  color: #767676;
}
.payment-root .box.third .content .item .coin {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #111111;
}
.payment-root .box.third .content .coin-progress {
  margin-top: 16px;
  max-width: 512px;
  width: 100%;
  height: 107px;
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #5c55fa;
  border-radius: 4px;
  background-color: rgba(92, 85, 250, 0.05);
}
.payment-root .box.third .content .coin-progress .text {
  font-weight: 700;
}
.payment-root .box.third .content .coin-progress .text .colored {
  color: #5c55fa;
}
.payment-root .box.third .content .coin-progress .progress-bar {
  width: 100%;
  height: 20px;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px;
}
.payment-root .box.third .content .coin-progress .progress-bar .inner-bar {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 30%;
  height: 14px;
  border-radius: 2px;
  background: linear-gradient(90deg, #a502fb, #5c55fa);
}
.payment-root .box.third .content .coin-progress .progress-bar .inner-bar .cursor {
  position: absolute;
  top: -14px;
  right: -6px;
}
.payment-root .box.third .content .coin-progress .progress-bar .inner-bar .cursor polygon {
  fill: #a502fb;
}
.payment-root .box.four {
  grid-column: 3/5;
  display: flex;
  flex-direction: column;
}
.payment-root .box.four .header .text {
  font-size: 20px;
  font-weight: 700;
}
.payment-root .box.four .content {
  width: 100%;
  flex-grow: 1;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.payment-root .box.four .content .display {
  flex-grow: 1;
  display: flex;
  gap: 44px;
  align-items: center;
}
.payment-root .box.four .content .display button {
  font-size: 44px;
  font-weight: 800;
}
.payment-root .box.four .content .display .coin {
  font-size: 32px;
  font-weight: 700;
}
.payment-root .box.four .content .charge {
  max-width: 398px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #5c55fa;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.payment-root .list-title {
  font-size: 24px;
  font-weight: bold;
}
.payment-root .divider {
  margin-top: 24px;
  height: 2px;
  width: 100%;
  background-color: #191919;
}
.payment-root .filter {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.payment-root .filter .apply {
  width: 56px;
  height: 40px;
  background-color: #111111;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
}
.payment-root .list {
  width: 100%;
  margin-top: 17px;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
.payment-root .list .header {
  display: flex;
  align-items: center;
  padding: 0px 60px;
  color: #767676;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #767676;
  height: 64px;
}
.payment-root .list .header .col:nth-child(1) {
  flex: 1;
}
.payment-root .list .header .col:nth-child(2) {
  flex: 5;
}
.payment-root .list .header .col:nth-child(3) {
  flex: 1;
  text-align: right;
}
.payment-root .list .header .col:nth-child(4) {
  flex: 1;
  text-align: right;
}
.payment-root .list .body {
  width: 100%;
}
.payment-root .list .body .row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 84px;
  padding: 0px 60px;
  color: #111111;
  font-size: 14px;
}
.payment-root .list .body .row:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
}
.payment-root .list .body .row .col {
  width: 100%;
}
.payment-root .list .body .row .col img {
  width: 50px;
  height: 50px;
  margin-right: 5%;
}
.payment-root .list .body .row .col:nth-child(1) {
  flex: 1;
  flex-shrink: 0;
}
.payment-root .list .body .row .col:nth-child(2) {
  display: flex;
  align-items: center;
  flex: 5;
  flex-shrink: 1;
}
.payment-root .list .body .row .col:nth-child(2) .text {
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.payment-root .list .body .row .col:nth-child(3) {
  flex: 1;
  flex-shrink: 0;
  text-align: right;
}
.payment-root .list .body .row .col:nth-child(4) {
  flex: 1;
  flex-shrink: 0;
  text-align: right;
}
.payment-root .list .more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 84px;
}
.payment-root .list .more .text {
  color: #767676;
  font-size: 14px;
  font-weight: 700;
  margin-right: 2px;
}

@media (max-width: 1420px) {
  .payment-root .box.first {
    grid-column: 1/5;
  }
  .payment-root .box.second {
    grid-column: 1/3;
  }
  .payment-root .box.third {
    grid-column: 3/5;
  }
  .payment-root .box.four {
    grid-column: 1/5;
  }
}
@media (max-width: 780px) {
  .payment-root {
    padding: 32px 16px 200px 16px;
  }
  .payment-root .box.first {
    grid-column: 1/5;
  }
  .payment-root .box.second {
    grid-column: 1/5;
  }
  .payment-root .box.third {
    grid-column: 1/5;
  }
  .payment-root .box.four {
    grid-column: 1/5;
  }
  .payment-root .filter {
    display: none;
  }
  .payment-root .list .header {
    padding: 0px 12px;
  }
  .payment-root .list .header .col:nth-child(2) {
    flex: 3;
  }
  .payment-root .list .body .row {
    padding: 0px 12px;
  }
  .payment-root .list .body .row .col:nth-child(2) {
    flex: 3;
  }
}
@media (max-width: 440px) {
  .payment-root .box {
    padding: 8px;
    height: 280px;
  }
  .payment-root .box.first .row .text {
    font-size: 20px;
  }
  .payment-root .box.first .row .coin {
    font-size: 28px;
  }
  .payment-root .box.second {
    padding: 16px;
  }
  .payment-root .box.second .arrow {
    bottom: 8px;
    right: 8px;
  }
  .payment-root .box.third {
    padding: 16px;
  }
  .payment-root .box.four {
    padding: 16px;
  }
  .payment-root .list .header {
    font-size: 12px;
  }
  .payment-root .list .body .row {
    font-size: 12px;
  }
  .payment-root .list .body .row .col img {
    width: 32px;
    height: 32px;
  }
}/*# sourceMappingURL=payment.style.css.map */