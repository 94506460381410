@import '../../../styles/color';

.activity-root {
  display: flex;
  width: 100%;

  .navigation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 248px;
    padding: 0px 16px;
    height: 100%;
    padding-top: 60px;

    .tab-item {
      transition: background-color 0.5s linear, color 0.5s linear;
      font-size: 18px;
      width: 100%;
      border-radius: 8px;
      height: 48px;

      &:hover {
        background-color: $primary-purple;
        color: #fff;
      }

      &.selected {
        background: linear-gradient(90deg, $primary-purple, $primary-blue);
        color: #fff;
      }
    }
  }
  .content-section {
    width: 100%;
    height: 860px;
  }
}
