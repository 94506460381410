@import '../../../styles/color';

.payment-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 80px 200px 80px;
  .section {
    max-width: 1640px;
    width: 100%;

    &:nth-child(1) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
    }
    &:nth-child(2) {
      margin-top: 90px;
    }
  }
  .box {
    height: 320px;
    padding: 30px;
    border: 1px solid $gray200;
    border-radius: 4px;
    box-shadow: 0px 3px 15px rgba($color: #000000, $alpha: 0.05);

    &.first {
      display: flex;
      align-items: center;
      grid-column: 1/5;
      .divider {
        width: 1px;
        height: 220px;
        background-color: $gray300;
      }
      .row {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        flex-grow: 1;

        .text {
          font-size: 24px;
          font-weight: 700;
          color: $gray600;
        }
        .coin {
          font-size: 32px;
          font-weight: 700;
          color: $black;
        }
        .history {
          width: 84px;
          height: 32px;
          border-radius: 16px;
          background-color: $gray200;
          color: $gray600;
          font-size: 14px;
        }
      }
    }

    &.second {
      grid-column: 1/2;
      position: relative;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .text {
          font-size: 20px;
          font-weight: 700;
        }
        button {
          width: 56px;
          height: 32px;
          border-radius: 16px;
          background-color: $gray200;
          color: $gray600;
          font-size: 14px;
        }
      }
      .content {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        .item {
          display: flex;
          flex-direction: column;
          .text {
            font-size: 14px;
            color: $gray600;
          }

          .coin {
            margin-top: 8px;
            font-size: 20px;
            font-weight: 700;
            color: $black;
          }
        }
      }
      .arrow {
        position: absolute;
        width: 24px;
        height: 24px;
        align-self: flex-end;
        right: 30px;
        bottom: 30px;
      }
    }

    &.third {
      grid-column: 2/3;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .text {
          font-size: 20px;
          font-weight: 700;
        }
        .benefit {
          font-size: 14px;
          color: $gray600;
          width: 115px;
          height: 32px;
          border: 1px solid $gray300;
          border-radius: 4px;
        }
      }
      .content {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          flex-direction: column;
          .text {
            font-size: 14px;
            color: $gray600;
          }
          .coin {
            margin-top: 8px;
            font-size: 20px;
            font-weight: 700;
            color: $black;
          }
        }

        .coin-progress {
          margin-top: 16px;
          max-width: 512px;
          width: 100%;
          height: 107px;
          padding: 20px 32px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          border: 1px solid $primary-blue;
          border-radius: 4px;
          background-color: rgba($color: $primary-blue, $alpha: 0.05);

          .text {
            font-weight: 700;
            .colored {
              color: $primary-blue;
            }
          }
          .progress-bar {
            width: 100%;
            height: 20px;
            background-color: #fff;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 3px;

            .inner-bar {
              position: relative;

              display: flex;
              justify-content: flex-end;
              width: 30%;
              height: 14px;
              border-radius: 2px;
              background: linear-gradient(
                90deg,
                $primary-purple,
                $primary-blue
              );

              .cursor {
                position: absolute;
                top: -14px;
                right: -6px;
                polygon {
                  fill: $primary-purple;
                }
              }
            }
          }
        }
      }
    }

    &.four {
      grid-column: 3/5;
      display: flex;
      flex-direction: column;

      .header {
        .text {
          font-size: 20px;
          font-weight: 700;
        }
      }

      .content {
        width: 100%;
        flex-grow: 1;
        align-self: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        .display {
          flex-grow: 1;
          display: flex;
          gap: 44px;
          align-items: center;

          button {
            font-size: 44px;
            font-weight: 800;
          }

          .coin {
            font-size: 32px;
            font-weight: 700;
          }
        }
        .charge {
          max-width: 398px;
          width: 100%;
          height: 48px;
          border-radius: 4px;
          background-color: $primary-blue;
          color: #fff;
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
  }

  .list-title {
    font-size: 24px;
    font-weight: bold;
  }
  .divider {
    margin-top: 24px;
    height: 2px;
    width: 100%;
    background-color: #191919;
  }
  .filter {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    .apply {
      width: 56px;
      height: 40px;
      background-color: $black;
      color: #fff;
      font-weight: 700;
      font-size: 14px;
      border-radius: 4px;
    }
  }
  .list {
    width: 100%;
    margin-top: 17px;
    background-color: $gray100;
    border: 1px solid $gray200;
    border-radius: 4px;

    .header {
      display: flex;
      align-items: center;
      padding: 0px 60px;
      color: $gray600;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid $gray600;
      height: 64px;

      .col {
        &:nth-child(1) {
          flex: 1;
        }
        &:nth-child(2) {
          flex: 5;
        }
        &:nth-child(3) {
          flex: 1;
          text-align: right;
        }
        &:nth-child(4) {
          flex: 1;
          text-align: right;
        }
      }
    }
    .body {
      width: 100%;
      .row {
        width: 100%;
        display: flex;
        align-items: center;
        height: 84px;
        padding: 0px 60px;
        color: $black;
        font-size: 14px;

        &:not(:last-child) {
          border-bottom: 1px solid $gray300;
        }

        .col {
          width: 100%;
          img {
            width: 50px;
            height: 50px;
            margin-right: 5%;
          }
          &:nth-child(1) {
            flex: 1;
            flex-shrink: 0;
          }
          &:nth-child(2) {
            display: flex;
            align-items: center;
            flex: 5;
            flex-shrink: 1;

            .text {
              display: block;
              overflow-x: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          &:nth-child(3) {
            flex: 1;
            flex-shrink: 0;

            text-align: right;
          }
          &:nth-child(4) {
            flex: 1;
            flex-shrink: 0;

            text-align: right;
          }
        }
      }
    }
    .more {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 84px;
      .text {
        color: $gray600;
        font-size: 14px;
        font-weight: 700;
        margin-right: 2px;
      }
    }
  }
}

@media (max-width: 1420px) {
  .payment-root {
    .section {
      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
    }
    .box {
      &.first {
        grid-column: 1/5;
      }
      &.second {
        grid-column: 1/3;
      }
      &.third {
        grid-column: 3/5;
      }
      &.four {
        grid-column: 1/5;
      }
    }

    .divider {
    }
    .row {
    }
  }
}

@media (max-width: 780px) {
  .payment-root {
    padding: 32px 16px 200px 16px;
    .section {
      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
    }
    .box {
      &.first {
        grid-column: 1/5;
      }
      &.second {
        grid-column: 1/5;
      }
      &.third {
        grid-column: 1/5;
      }
      &.four {
        grid-column: 1/5;
      }
    }

    .divider {
    }
    .row {
    }

    .list-title {
    }
    .divider {
    }
    .filter {
      display: none;

      .apply {
      }
    }
    .list {
      .header {
        padding: 0px 12px;

        .col {
          &:nth-child(1) {
          }
          &:nth-child(2) {
            flex: 3;
          }
          &:nth-child(3) {
          }
          &:nth-child(4) {
          }
        }
      }
      .body {
        .row {
          padding: 0px 12px;

          &:not(:last-child) {
          }

          .col {
            img {
            }
            &:nth-child(1) {
            }
            &:nth-child(2) {
              flex: 3;
            }
            &:nth-child(3) {
            }
            &:nth-child(4) {
            }
          }
        }
      }
      .more {
        .text {
        }
      }
    }
  }
}

@media (max-width: 440px) {
  .payment-root {
    .section {
      &:nth-child(1) {
      }
      &:nth-child(2) {
      }
    }
    .box {
      padding: 8px;
      height: 280px;
      &.first {
        .row {
          .text {
            font-size: 20px;
          }
          .coin {
            font-size: 28px;
          }
        }
      }
      &.second {
        padding: 16px;
        .arrow {
          bottom: 8px;
          right: 8px;
        }
      }
      &.third {
        padding: 16px;
      }
      &.four {
        padding: 16px;
      }
    }

    .divider {
    }
    .row {
    }

    .list-title {
    }
    .divider {
    }
    .filter {
      .apply {
      }
    }
    .list {
      .header {
        font-size: 12px;
        .col {
          &:nth-child(1) {
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
          }
          &:nth-child(4) {
          }
        }
      }
      .body {
        .row {
          font-size: 12px;
          &:not(:last-child) {
          }

          .col {
            img {
              width: 32px;
              height: 32px;
            }
            &:nth-child(1) {
            }
            &:nth-child(2) {
            }
            &:nth-child(3) {
            }
            &:nth-child(4) {
            }
          }
        }
      }
      .more {
        .text {
        }
      }
    }
  }
}
