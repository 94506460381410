@import '../../../styles/color';
@import '../../../styles/mixin';

.main {
  width: 100%;
  height: 100%;

  display: flex;
  position: absolute;
  top: 0px;
  justify-content: center;
  background: linear-gradient(94deg, #ff02a0, #a502fb);
  &-body {
    padding-top: 84px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      font-size: 14px;
      color: white;
    }
    &-table {
      margin-top: 80px;
      width: fit-content;
      height: 480px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px 30px;
      .menu-tab {
        position: relative;
        width: 20vw;
        height: 30vh;
        background-color: $gray300;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        overflow: hidden;

        img {
          filter: brightness(0.75);
        }
        div {
          z-index: 5;
          position: absolute;

          left: 10px;
          bottom: 30px;
          font-weight: 800;
          font-size: 20px;
          color: white;
        }
      }
      .menu-tab:hover {
        img {
          filter: brightness(1);
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .main {
    &-body {
      padding-top: 100px;
      p {
        text-align: center;
        font-size: 14px;
        color: white;
        width: 280px;
      }
      &-table {
        margin-top: 40px;
        width: 300px;
        height: 520px;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 20px;
        .menu-tab {
          width: 140px;
          height: 160px;

          div {
            font-size: 14px;
          }
        }
      }
    }
  }
}
