@import '../../../styles/color';
@import '../../../styles/mixin';

#root {
  .video-list-root {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;
    gap: 16px;

    @media (max-width: 1600px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include tablet {
      grid-template-columns: minmax(250px, auto);
    }

    .item-container {
      width: 100%;
      .item {
        width: 100%;
        height: 100%;
        cursor: pointer;
        max-width: 100%;
      }
    }

    .player-container {
      grid-column: 1 / 5;
      display: grid;
      grid-template-columns: calc(50% - 8px) calc(50% + 8px);

      @media (max-width: 1600px) {
        grid-column: 1 / 4;
        display: flex;
        flex-direction: column;
        height: fit-content;
      }

      @include desktop {
        grid-column: 1 / 3;
      }

      @include tablet {
        grid-column: 1 / 2;
      }

      .player {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }

      .description {
        position: relative;
        width: 100%;
        background-color: $gray100;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title-container {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .title {
            font-weight: 600;
            font-size: 20px;
            color: $black;
          }

          .artist {
            font-size: 14px;
            color: $black;
          }
        }

        .detail-container {
          margin-top: 72px;
          display: grid;
          grid-template-columns: repeat(2, 220px);
          row-gap: 8px;
          column-gap: 24px;

          @media (max-width: 560px) {
            grid-template-columns: 1fr;
          }

          .item {
            display: flex;
            gap: 25px;
            font-size: 14px;

            .label {
              color: $gray600;
              width: 80px;
            }

            .value {
              color: $black;
            }
          }
        }

        .resolution-container {
          background-color: $gray300;
          padding: 10px 10px 20px;
          margin-top: 40px;

          .title {
            color: $gray600;
            font-size: 13px;
            margin-bottom: 12px;
          }

          .item {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            margin-bottom: 6px;

            &:last-child {
              margin-bottom: 0;
            }

            .label {
              color: $black;
            }

            .value {
              color: $primary-blue;
            }
          }
        }

        .bottom-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 40px;

          .tag-container {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .title {
              font-size: 14px;
              color: $gray600;
            }

            .item-list {
              display: flex;
              gap: 8px;
              justify-content: flex-start;
              flex-wrap: wrap;

              .item {
                width: fit-content;
                border: 1px solid $gray200;
                background-color: $white;
                color: $gray600;
                padding: 7px 12px;
                border-radius: 16px;
                font-size: 14px;
              }
            }
          }

          .option-container {
            position: relative;
            display: flex;
            gap: 20px;

            .btn {
              cursor: pointer;
            }

            .download-dropdown {
              position: absolute;
              bottom: -90px;
              left: -50%;
              color: $black;
              font-size: 14px;
              width: 123px;
              box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
              border-radius: 4px;

              &::before {
                position: absolute;
                top: -6px;
                left: calc(50% - 3px);
                display: block;
                content: ' ';
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $white;
              }

              .down-btn {
                display: block;
                padding: 12px 16px;
                color: $black;
                background-color: $white;
                cursor: pointer;

                &:first-child {
                  border-radius: 4px 4px 0 0;
                }

                &:last-child {
                  border-radius: 0 0 4px 4px;
                }

                &:hover {
                  background-color: #e8e7fb;
                }
              }
            }
          }
        }

        .close-btn {
          position: absolute;
          top: 8px;
          right: 8px;
        }
      }
    }
  }
}
