@mixin font-setting($color, $font-weight: normal, $font-size) {
  color: $color;
  font-size: $font-size;
  @if $font-weight != normal {
    font-weight: $font-weight;
  }
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.footer-off {
  display: none;
}

.footer {
  // &-search {
  //   height: 350px;
  //   background-color: #f9f9f9;
  //   width: 100vw;
  //   .keyword {
  //     width: 100vw;
  //     height: 188px;
  //     margin-left: 140px;
  //     margin-top: 52px;
  //     position: absolute;
  //     grid-template-columns: repeat(6, 1fr);
  //     row-gap: 24px;
  //     column-gap: 16px;
  //     &-title {
  //       height: 29px;
  //       margin-bottom: 24px;
  //       @include font-setting(#000, bold, 24px);
  //     }
  //     &-main {
  //       height: 135px;
  //       display: grid;
  //       grid-template-columns: repeat(6, 1fr);
  //       row-gap: 36px;
  //       column-gap: 16px;
  //       @include font-setting(#000, 600, 18px);
  //     }
  //   }
  // }
  &-info {
    width: 100%;
    height: 303px;
    display: flex;
    position: relative;
    background-color: #000;
    &-location {
      width: 485px;
      height: 140px;

      margin-top: 80px;
      margin-left: 7.3vw;
      button {
        @include font-setting(#fff, bold, 14px);
        margin-right: 10px;
        margin-bottom: 24px;
      }
      span {
        margin-right: 10px;
        @include font-setting(#999999, normal, 14px);
      }
      p {
        @include font-setting(#999999, normal, 14px);
        line-height: 1.8;
        button {
          @include font-setting(#fff, normal, 14px);
          text-decoration: underline;
          margin-left: 10px;
        }
      }
    }
    &-address {
      width: 150px;
      height: 123px;
      margin-top: 80px;
      margin-left: 17.4vw;
      .address-white {
        @include font-setting(#fff, bold, 14px);
        margin-bottom: 10px;
      }
      .address-gray {
        @include font-setting(#999999, bold, 16px);
        margin-bottom: 31px;
      }
    }
    &-social {
      width: 156px;
      height: 73px;
      position: absolute;
      top: 80px;
      right: 7.3vw;
      div {
        @include font-setting(#fff, bold, 14px);
        margin-bottom: 24px;
      }
      img {
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .footer-info {
    height: 387px;
    &-location {
      width: 400px;
      height: 163px;
      position: absolute;
      top: 80px;
      left: 80px;
      margin: 0 0 0 0;
      p {
        font-size: 12px;
        line-height: 2.2;
        button {
          font-size: 12px;
        }
      }
    }
    &-address {
      position: absolute;
      top: 80px;
      right: 80px;
      margin: 0 0 0 0;
    }
    &-social {
      position: absolute;
      top: 234px;
      right: 75px;
    }
  }
}

@media (max-width: 900px) {
  .footer-info {
    &-location {
      left: 32px;
    }
    &-address {
      right: 32px;
    }
    &-social {
      right: 27px;
    }
  }
}
@media (max-width: 720px) {
  .footer-info {
    width: 720px;
  }
}
