@import '../../../styles/color';

.fix-userinfo-root {
  padding: 30px 32px 140px 32px;
  display: flex;
  justify-content: center;

  .shadow-box {
    box-shadow: 0px 3px 15px rgba($color: #000000, $alpha: 0.05);
    max-width: 1640px;
    width: 100%;
    border: 1px solid $gray200;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 52px 20px 56px 20px;
  }
  .wrapper {
    max-width: 920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    input[type='file'] {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
    }
    .img-upload {
      cursor: pointer;
      margin-top: 20px;
      border: 1px solid $primary-blue;
      color: $primary-blue;
      width: 136px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
    }
    .user-img {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      margin-top: 20px;
    }
  }
  .user-form {
    margin-top: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .row {
      width: 100%;
      display: flex;
      gap: 24px;
      &:not(:first-child) {
        margin-top: 24px;
      }
    }
    .store-button {
      margin-top: 62px;
      height: 40px;
      width: 136px;
      font-size: 18px;
      color: $primary-blue;
      align-self: flex-end;
      border: 1px solid $primary-blue;
      border-radius: 20px;
    }
  }
}

@media (max-width: 740px) {
  .fix-userinfo-root {
    padding: 30px 16px 140px 16px;
    .shadow-box {
      padding: 52px 8px 56px 8px;
    }
    .wrapper {
    }
    .profile-section {
      .title {
      }
      .user-img {
      }
      .img-upload {
      }
    }
    .user-form {
      .row {
        flex-direction: column;
      }
    }
  }
}
