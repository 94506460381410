@mixin font-setting($color, $font-weight: normal, $font-size) {
  color: $color;
  font-size: $font-size;
  @if $font-weight != normal {
    font-weight: $font-weight;
  }
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.splash {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  video {
    object-fit: fill;
  }

  .splash-video {
    width: 100%;
    height: 100%;
  }

  &-box {
    position: relative;
    width: 380px;
    height: 149px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div {
      height: 54.5px;
      @include font-setting(#fff, bold, 39px);
      line-height: 1.8;
    }
    .little {
      @include font-setting(#e5e5e5, normal, 16px);
      margin-top: 20px;
    }
  }

  &-button {
    width: 180px;
    height: 40px;

    position: absolute;
    right: 140px;
    bottom: 80px;

    .skip-fix {
      width: 100px;
      height: 40px;
      border-radius: 4px;
      @include font-setting(#ffffff, 600, 14px);
      background-color: rgba($color: #000000, $alpha: 0.3);
      margin-right: 8px;
    }
    .skip-now {
      width: 72px;
      height: 40px;
      border-radius: 4px;
      background-color: #fff;
      @include font-setting(#191919, 600, 14px);
    }
  }
}

@media (max-width: 1024px) {
  .splash {
    &-button {
      left: 68.75vw;
    }
  }
}

@media (max-width: 720px) {
  .splash {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //justify-content: flex-start;
    &-box {
      position: fixed;
    }
    &-button {
      position: inherit;
      right: 0;
      left: 0;
      bottom: 0;
      margin-top: 646px;
    }
  }
}
