.download-root {
  padding: 0px 140px;
}
.download-root .title {
  margin-top: 56px;
  font-size: 24px;
  font-weight: bold;
}
.download-root .divider {
  margin-top: 24px;
  width: 100%;
  height: 2px;
  background-color: #191919;
}
.download-root .content {
  margin-top: 16px;
}
.download-root .content .text {
  color: #767676;
  text-align: center;
  width: 100%;
  padding: 100px 0px;
}/*# sourceMappingURL=download.style.css.map */