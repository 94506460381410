.fix-userinfo-root {
  padding: 30px 32px 140px 32px;
  display: flex;
  justify-content: center;
}
.fix-userinfo-root .shadow-box {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  max-width: 1640px;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 52px 20px 56px 20px;
}
.fix-userinfo-root .wrapper {
  max-width: 920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fix-userinfo-root .profile-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fix-userinfo-root .profile-section input[type=file] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.fix-userinfo-root .profile-section .img-upload {
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid #5c55fa;
  color: #5c55fa;
  width: 136px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fix-userinfo-root .profile-section .title {
  font-size: 20px;
  font-weight: 700;
}
.fix-userinfo-root .profile-section .user-img {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin-top: 20px;
}
.fix-userinfo-root .user-form {
  margin-top: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.fix-userinfo-root .user-form .row {
  width: 100%;
  display: flex;
  gap: 24px;
}
.fix-userinfo-root .user-form .row:not(:first-child) {
  margin-top: 24px;
}
.fix-userinfo-root .user-form .store-button {
  margin-top: 62px;
  height: 40px;
  width: 136px;
  font-size: 18px;
  color: #5c55fa;
  align-self: flex-end;
  border: 1px solid #5c55fa;
  border-radius: 20px;
}

@media (max-width: 740px) {
  .fix-userinfo-root {
    padding: 30px 16px 140px 16px;
  }
  .fix-userinfo-root .shadow-box {
    padding: 52px 8px 56px 8px;
  }
  .fix-userinfo-root .user-form .row {
    flex-direction: column;
  }
}/*# sourceMappingURL=fix.userinfo.style.css.map */