@import './color';
@import './mixin';

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Pretendard', sans-serif;
  list-style: none;
  text-decoration: none;
  font-weight: 400;
}

button {
  background-color: transparent;
  cursor: pointer;
}

div {
  cursor: default;
}

html {
  font-size: 16px;
  touch-action: pan-y;
}

#root {
  min-height: 100vh;
  overflow-x: hidden;

  .gnb {
    position: absolute;
    top: 0;
  }

  .page-root {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }

  .search-section {
    width: 100%;
    height: 390px;
    display: flex;
    padding-top: 180px;
    justify-content: center;
    // background-color: $gray600;

    &.image {
      background: url('/public/assets/images/bg/image-bg.jpg') no-repeat;
      background-size: cover;
      background-position-y: center;
    }

    &.video {
      background: url('/public/assets/images/bg/video-bg.jpg') no-repeat;
      background-size: cover;
      background-position-y: center;
    }

    &.music {
      background: url('/public/assets/images/bg/music-bg.jpg') no-repeat;
      background-size: cover;
      background-position-y: center;
    }

    .wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      @include desktop {
        padding: 0 80px;
      }

      @include tablet {
        padding: 0 32px;
      }

      .tags-container {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .tag {
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 16px;
          color: $white;
          font-size: 14px;
          padding: 7px 12px;
          cursor: pointer;
        }
      }
    }
  }

  .recommend-section {
    max-width: 1640px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;
      width: fit-content;
      .span {
        font-size: 24px;
        font-weight: 700;
        color: $black;
      }
    }

    .cards-container {
      top: 50px;
      display: flex;
      gap: 16px;
      overflow-x: scroll;
      width: 100vw;
      transform: translateX(-140px);

      @include desktop {
        transform: translateX(-80px);
      }

      @include tablet {
        transform: translateX(-32px);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .card {
        &:first-child {
          margin-left: 140px;

          @include desktop {
            margin-left: 80px;
          }

          @include tablet {
            margin-left: 32px;
          }
        }

        &:last-child {
          margin-right: 16px;
        }

        position: relative;
        display: flex;
        flex-direction: column;
        width: 260px;
        height: 183px;
        min-width: 260px;
        max-height: 183px;
        background-color: $gray100;
        border: 1px solid $gray300;
        padding: 24px;
        border-radius: 4px;
        cursor: pointer;

        .tag {
          font-size: 24px;
          font-weight: 600;
          color: $black;
          margin-bottom: 10px;
        }

        .description {
          height: 40px;
          white-space: pre-wrap;
          font-size: 15px;
          color: $gray600;
          line-height: 22px;
          margin-bottom: 20px;
        }

        .count {
          height: 36px;
          background-color: $gray300;
          padding: 10px 12px;
          color: $gray600;
          font-size: 15px;
          width: fit-content;
          border-radius: 18px;
        }

        .hover {
          position: absolute;
          display: none;
          right: 24px;
          bottom: 24px;
        }

        &:hover {
          border-color: $primary-blue;

          .hover {
            display: block;
          }
        }
      }
    }
  }

  .new-section {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include tablet {
      margin-top: 115px;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      width: fit-content;

      .span {
        font-size: 24px;
        font-weight: 700;
        color: $black;
      }
    }
  }

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &::-webkit-scrollbar {
      display: none;
    }

    overflow-y: scroll;
    overflow-x: scroll;

    .backdrop {
      transition: opacity 0.3s;
      position: absolute;
      background-color: #000;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      z-index: 5;
    }

    .popup {
      width: 100%;
      padding: 0px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 6;
      transition: transform 0.3s ease-in-out;
    }
  }
}
