.activity-root {
  display: flex;
  width: 100%;
}
.activity-root .navigation {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 248px;
  padding: 0px 16px;
  height: 100%;
  padding-top: 60px;
}
.activity-root .navigation .tab-item {
  transition: background-color 0.5s linear, color 0.5s linear;
  font-size: 18px;
  width: 100%;
  border-radius: 8px;
  height: 48px;
}
.activity-root .navigation .tab-item:hover {
  background-color: #a502fb;
  color: #fff;
}
.activity-root .navigation .tab-item.selected {
  background: linear-gradient(90deg, #a502fb, #5c55fa);
  color: #fff;
}
.activity-root .content-section {
  width: 100%;
  height: 860px;
}/*# sourceMappingURL=activity.style.css.map */