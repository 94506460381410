@import '../../../../styles/color';

.cselect-root {
  position: relative;
  margin: 0px 12px;

  .cselect-input {
    width: 116px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid $gray300;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 16px;
    background-color: $white;
    cursor: pointer;

    &.focus {
      border: 1px solid $primary-blue;
    }

    .cselect-label {
      display: block;
      color: $black;
      flex: 1;
      font-size: 14px;
    }

    .cselect-under-arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .cselect-option {
    position: absolute;
    border-radius: 4px;
    background-color: $gray100;
    cursor: pointer;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
    transition-duration: 300ms;
    transition-property: max-height;
    z-index: 999;
    margin-top: 4px;
    overflow: hidden;

    &.hide {
      max-height: 0px;
      overflow: hidden;
    }

    &.show {
      max-height: 1000px;
    }

    .cselect-option-item {
      width: 116px;
      height: 40px;
      padding: 11px 16px;
      display: flex;
      align-items: center;
      color: $gray600;
      font-size: 14px;

      &:hover {
        background: #e8e7fb;
        color: $black;
      }

      &.selected {
        color: $black;
      }
    }
  }
}
