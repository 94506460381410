@import '../../../styles/color';

.tab-root {
  padding: 0px 16px;
  height: 57px;
  border-top: 1px solid $gray300;
  border-bottom: 1px solid $gray300;
  background-color: $gray100;
  display: flex;
  justify-content: center;

  .wrapper {
    display: flex;
    position: relative;
    max-width: 380px;
    width: 100%;
  }

  .tab-button {
    color: $gray600;
    height: 100%;
    font-size: 16px;
    max-width: 95px;
    width: 100%;

    &.active {
      font-weight: 700;
      color: #000000;
    }
  }

  .underline {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 25%;
    background-color: #000000;
    transition: left 0.2s ease-in-out;
    &.userinfo {
      left: 0;
    }

    &.download {
      left: 25%;
    }

    &.payment {
      left: 50%;
    }

    &.activity {
      left: 75%;
    }
  }
}
