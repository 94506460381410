@import '../../../styles/color';
@import '../../../styles/mixin';
@mixin font-setting($color, $font-weight: normal, $font-size) {
  color: $color;
  font-size: $font-size;
  @if $font-weight != normal {
    font-weight: $font-weight;
  }
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#root {
  .music-root {
    .main-section {
      padding: 56px 140px 120px;
      .new-section {
        .play-container {
          width: 87.5vw;
          border-top: 2px solid black;
        }
      }

      @include desktop {
        padding-left: 80px;
      }

      @include tablet {
        padding-left: 32px;
      }

      @include mobile {
      }
    }
  }
}

@media (max-width: 720px) {
  #root {
    .music-root {
      .main-section {
        .new-section {
          .play-container {
            width: 85vw;
          }
        }
      }
    }
  }
}
