.gnb-off {
  display: none;
}

.gnb, .gnb-main {
  width: 100%;
  height: 84px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
  position: relative;
}
.gnb-logo {
  position: absolute;
  left: 140px;
  bottom: -12px;
  width: 116px;
  height: 116px;
}
.gnb .gnb-bar, .gnb-main .gnb-bar {
  display: none;
  position: absolute;
  top: 27px;
  left: 32px;
}
.gnb .side-menu, .gnb-main .side-menu {
  z-index: 2;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.gnb .side-menu-bar, .gnb-main .side-menu-bar {
  width: 352px;
  height: 100vh;
  background-color: white;
}
.gnb .side-menu-bar img, .gnb-main .side-menu-bar img {
  margin: 26px 288px 42px 32px;
}
.gnb .side-menu-bar button, .gnb-main .side-menu-bar button {
  color: #000;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0 250px 29px 32px;
}
.gnb-logo {
  position: absolute;
  left: 6.6vw;
}
.gnb .menu, .gnb-main .menu {
  width: 524px;
  height: 19px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 33px;
  left: 17.5vw;
}
.gnb .menu .tab, .gnb-main .menu .tab {
  color: #ebebeb;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.gnb .menu .tab.active, .gnb-main .menu .tab.active {
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: underline;
}
.gnb .gnb-user, .gnb-main .gnb-user {
  display: flex;
  align-items: center;
  width: 400px;
  height: 32px;
  position: absolute;
  top: 27px;
  right: 6vw;
}
.gnb .gnb-user div, .gnb-main .gnb-user div {
  color: #fff;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.gnb .gnb-user .welcome, .gnb-main .gnb-user .welcome {
  cursor: pointer;
  margin-right: 38px;
}
.gnb .gnb-user .coin-image, .gnb-main .gnb-user .coin-image {
  cursor: pointer;
}
.gnb .gnb-user .coin-number, .gnb-main .gnb-user .coin-number {
  cursor: pointer;
  margin-right: 56px;
}
.gnb .gnb-user .cart, .gnb-main .gnb-user .cart {
  cursor: pointer;
  margin-right: 24px;
}
.gnb .gnb-user .user-img, .gnb-main .gnb-user .user-img {
  cursor: pointer;
}
.gnb .gnb-button, .gnb-main .gnb-button {
  width: 140px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 7.3vw;
}
.gnb .gnb-button-login, .gnb-main .gnb-button-login, .gnb .gnb-button-sign, .gnb-main .gnb-button-sign {
  width: 60px;
  height: 40px;
  margin-right: 8px;
  background-color: #ededed;
  color: #767676;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 4px;
}
.gnb .gnb-button-sign, .gnb-main .gnb-button-sign {
  width: 72px;
  margin-right: 0px;
  background-color: #5c55fa;
  color: white;
}
.gnb.black .menu .tab, .black.gnb-main .menu .tab {
  color: #000000;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.gnb.black .menu .tab.active, .black.gnb-main .menu .tab.active {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: underline;
}
.gnb.black .gnb-user, .black.gnb-main .gnb-user {
  filter: invert(100%);
}

.gnb-main {
  background-color: rgba(0, 0, 0, 0);
}
.gnb-main .gnb-logo {
  width: 106px;
  height: 32px;
  top: 26px;
}
.gnb-main .menu {
  display: none;
}

@media (max-width: 1300px) {
  .gnb-logo {
    left: 80px;
  }
  .gnb .menu, .gnb-main .menu {
    width: 428px;
    left: 236px;
  }
  .gnb .gnb-user, .gnb-main .gnb-user {
    width: 88px;
    right: 80px;
  }
  .gnb .gnb-user div, .gnb-main .gnb-user div {
    display: none;
  }
  .gnb .gnb-user .coin-image, .gnb-main .gnb-user .coin-image {
    display: none;
  }
}
@media (max-width: 900px) {
  .gnb .gnb-bar, .gnb-main .gnb-bar {
    display: block;
  }
  .gnb .menu, .gnb-main .menu {
    display: none;
  }
  .gnb-logo {
    left: 41.94vw;
  }
  .gnb .gnb-button, .gnb-main .gnb-button {
    right: 32px;
  }
  .gnb .gnb-user, .gnb-main .gnb-user {
    right: 32px;
  }
}
@media (max-width: 580px) {
  .gnb-logo {
    display: none;
  }
}/*# sourceMappingURL=gnb.style.css.map */