@import '../../../../styles/color';

#root {
  .cdate-root {
    position: relative;
    width: 132px;
    height: 40px;
    background-color: $white;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    border: 1px solid $gray300;

    &:focus-within {
      border: 1px solid $primary-blue;
    }

    input[type='date']::-webkit-datetime-edit-text {
      -webkit-appearance: none;
      display: none;
    }

    input[type='date']::-webkit-datetime-edit-month-field {
      -webkit-appearance: none;
      display: none;
    }

    input[type='date']::-webkit-datetime-edit-day-field {
      -webkit-appearance: none;
      display: none;
    }

    input[type='date']::-webkit-datetime-edit-year-field {
      -webkit-appearance: none;
      display: none;
    }

    .date-input {
      width: 0;
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        color: transparent;
        cursor: pointer;
        position: absolute;
        width: 132px;
        height: 40px;
        top: 0;
        left: 0;
      }
    }

    .date-label {
      display: flex;
      align-items: center;
      gap: 14px;

      .date-span {
        flex: 1;
        font-size: 14px;
        color: $black;
      }
    }
  }
}
