.musicplay {
  position: relative;
  border-top: 1px solid #dbdbdb;
  width: 87.5vw;
  height: 84px;
  display: flex;
  align-items: center;
}
.musicplay-button {
  margin-left: 10px;
}
.musicplay-image {
  margin-left: 16px;
  width: 50px;
  height: 50px;
}
.musicplay-title {
  position: absolute;
  width: 260px;
  height: 19px;
  top: 23px;
  left: 117px;
  color: #111111;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.musicplay-artist {
  position: absolute;
  width: 250px;
  height: 17px;
  top: 44px;
  left: 117px;
  color: #767676;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.musicplay-tag {
  width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #767676;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  left: 417px;
}
.musicplay .progress-container-on, .musicplay .progress-container-off {
  width: 320px;
  display: flex;
  align-items: center;
  margin-left: 609px;
  position: relative;
}
.musicplay .progress-container-on progress, .musicplay .progress-container-off progress {
  width: 320px;
  height: 3px;
  -webkit-appearance: none;
}
.musicplay .progress-container-on ::-webkit-progress-bar, .musicplay .progress-container-off ::-webkit-progress-bar {
  background-color: #dbdbdb;
}
.musicplay .progress-container-on ::-webkit-progress-value, .musicplay .progress-container-off ::-webkit-progress-value {
  background-color: #5c55fa;
}
.musicplay .progress-container-on .point, .musicplay .progress-container-off .point {
  width: 16px;
  height: 16px;
  background-color: #5c55fa;
  border-radius: 50%;
}
.musicplay .progress-container-off ::-webkit-progress-value {
  background-color: #767676;
}
.musicplay .progress-container-off .point {
  display: none;
}
.musicplay-time {
  width: 95px;
  height: 18px;
}
.musicplay-time .now-time {
  color: #767676;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 10px;
  margin-right: 5px;
}
.musicplay-time .play-time {
  color: #111111;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.musicplay-icon {
  position: absolute;
  display: flex;
  right: 10px;
  justify-content: space-between;
  width: 106px;
  height: 22px;
}
.musicplay-icon .download-popup {
  position: absolute;
  right: 36px;
  top: 32px;
  width: 123px;
  height: 80px;
  z-index: 1;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
}
.musicplay-icon .download-popup .popup-box, .musicplay-icon .download-popup .popup-box:hover {
  width: 123px;
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.musicplay-icon .download-popup .popup-box span, .musicplay-icon .download-popup .popup-box:hover span,
.musicplay-icon .download-popup .popup-box a,
.musicplay-icon .download-popup .popup-box:hover a {
  width: 91px;
  height: 17px;
  color: #111111;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.musicplay-icon .download-popup .popup-box:hover {
  background-color: rgba(92, 85, 250, 0.1);
}
.musicplay-icon .download-popup::before {
  position: absolute;
  top: -6px;
  left: calc(50% - 3px);
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
}

@media (max-width: 1500px) {
  .musicplay-tag {
    display: none;
  }
  .musicplay .progress-container-on, .musicplay .progress-container-off {
    width: 161px;
    margin-left: 30.17vw;
  }
  .musicplay .progress-container-on progress, .musicplay .progress-container-off progress {
    width: 161px;
  }
}
@media (max-width: 900px) {
  .musicplay-title {
    width: 163px;
  }
  .musicplay-artist {
    width: 163px;
  }
  .musicplay .progress-container-on, .musicplay .progress-container-off {
    display: none;
  }
  .musicplay-time {
    margin-left: 188px;
  }
}
@media (max-width: 720px) {
  .musicplay {
    width: 85vw;
  }
  .musicplay-button {
    width: 20px;
    height: 20px;
  }
  .musicplay-image {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
  .musicplay-title {
    width: 100px;
    font-size: 10px;
    top: 30px;
    left: 90px;
  }
  .musicplay-artist {
    width: 100px;
    font-size: 10px;
    left: 90px;
  }
  .musicplay-time {
    position: absolute;
    right: 0px;
    top: 25px;
  }
  .musicplay-time .now-time {
    font-size: 11px;
  }
  .musicplay-time .play-time {
    font-size: 11px;
  }
  .musicplay-icon {
    width: 68px;
    height: 16px;
    top: 45px;
    right: 17px;
  }
  .musicplay-icon img {
    width: 16px;
    height: 16px;
  }
  .musicplay-icon .download-popup {
    right: 2px;
  }
}/*# sourceMappingURL=musicplay.styles.css.map */