@import '../../../styles/color';
@import '../../../styles/mixin';
@mixin font-setting($color, $font-weight: normal, $font-size) {
  color: $color;
  font-size: $font-size;
  @if $font-weight != normal {
    font-weight: $font-weight;
  }
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.musicplay {
  position: relative;
  border-top: 1px solid $gray300;
  width: 87.5vw;
  height: 84px;
  display: flex;
  align-items: center;
  &-button {
    margin-left: 10px;
  }
  &-image {
    margin-left: 16px;
    width: 50px;
    height: 50px;
    // background-image: image-set(
    //     url(/) 1x,
    //     url(/) 2x,
    //     url(/) 3x,
    //   );
  }
  &-title {
    position: absolute;
    width: 260px;

    height: 19px;
    top: 23px;
    left: 117px;
    @include font-setting($black, normal, 16px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-artist {
    position: absolute;
    width: 250px;

    height: 17px;
    top: 44px;
    left: 117px;
    @include font-setting($gray600, normal, 14px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-tag {
    width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include font-setting($gray600, normal, 14px);
    position: absolute;
    left: 417px;
  }
  .progress-container-on {
    width: 320px;
    display: flex;
    align-items: center;
    margin-left: 609px;
    position: relative;
    progress {
      width: 320px;
      height: 3px;

      -webkit-appearance: none;
    }
    ::-webkit-progress-bar {
      background-color: $gray300;
    }

    ::-webkit-progress-value {
      background-color: $primary-blue;
    }

    .point {
      width: 16px;
      height: 16px;
      background-color: $primary-blue;
      border-radius: 50%;
    }
  }
  .progress-container-off {
    @extend .progress-container-on;
    ::-webkit-progress-value {
      background-color: $gray600;
    }
    .point {
      display: none;
    }
  }
  &-time {
    width: 95px;
    height: 18px;
    .now-time {
      @include font-setting($gray600, normal, 13px);
      margin-left: 10px;
      margin-right: 5px;
    }
    .play-time {
      @include font-setting($black, normal, 13px);
    }
  }

  &-icon {
    position: absolute;
    display: flex;
    right: 10px;
    //margin-left: 99px;
    justify-content: space-between;
    width: 106px;
    height: 22px;
    .download-popup {
      position: absolute;
      right: 36px;
      top: 32px;
      width: 123px;
      height: 80px;
      z-index: 1;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
      background-color: white;
      .popup-box {
        width: 123px;
        height: 40px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span,
        a {
          width: 91px;
          height: 17px;
          @include font-setting($black, normal, 14px);
        }
      }
      .popup-box:hover {
        @extend .popup-box;

        background-color: rgba(92, 85, 250, 0.1);
      }
      &::before {
        position: absolute;
        top: -6px;
        left: calc(50% - 3px);
        display: block;
        content: ' ';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $white;
      }
    }
  }
}

@media (max-width: 1500px) {
  .musicplay {
    &-tag {
      display: none;
    }

    .progress-container-on {
      width: 161px;
      margin-left: 30.17vw;
      progress {
        width: 161px;
      }
    }
  }
}

@media (max-width: 900px) {
  .musicplay {
    &-title {
      width: 163px;
    }
    &-artist {
      width: 163px;
    }
    .progress-container-on {
      display: none;
    }
    &-time {
      margin-left: 188px;
    }
  }
}

@media (max-width: 720px) {
  .musicplay {
    width: 85vw;
    &-button {
      width: 20px;
      height: 20px;
    }
    &-image {
      width: 40px;
      height: 40px;
      margin-left: 10px;
    }
    &-title {
      width: 100px;
      font-size: 10px;
      top: 30px;
      left: 90px;
    }
    &-artist {
      width: 100px;
      font-size: 10px;
      left: 90px;
    }
    &-time {
      position: absolute;
      right: 0px;
      top: 25px;
      .now-time {
        font-size: 11px;
      }
      .play-time {
        font-size: 11px;
      }
    }
    &-icon {
      width: 68px;
      height: 16px;
      top: 45px;
      right: 17px;
      img {
        width: 16px;
        height: 16px;
      }
      .download-popup {
        right: 2px;
      }
    }
  }
}
