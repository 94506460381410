#root .illustrator-list-root {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: dense;
  gap: 16px;
}
@media (max-width: 1600px) {
  #root .illustrator-list-root {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1024px) {
  #root .illustrator-list-root {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 720px) {
  #root .illustrator-list-root {
    grid-template-columns: minmax(250px, auto);
  }
}
#root .illustrator-list-root .item-container {
  width: 100%;
}
#root .illustrator-list-root .item-container .item {
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-width: 100%;
}
#root .illustrator-list-root .player-container {
  grid-column: 1/5;
  display: grid;
  grid-template-columns: calc(50% - 8px) calc(50% + 8px);
}
@media (max-width: 1600px) {
  #root .illustrator-list-root .player-container {
    grid-column: 1/4;
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (max-width: 1024px) {
  #root .illustrator-list-root .player-container {
    grid-column: 1/3;
  }
}
@media (max-width: 720px) {
  #root .illustrator-list-root .player-container {
    grid-column: 1/2;
  }
}
#root .illustrator-list-root .player-container .player {
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  background-color: #767676;
  min-height: 300px;
}
#root .illustrator-list-root .player-container .player canvas {
  min-height: 300px;
}
#root .illustrator-list-root .player-container .description {
  position: relative;
  width: 100%;
  background-color: #f9f9f9;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#root .illustrator-list-root .player-container .description .title-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
#root .illustrator-list-root .player-container .description .title-container .title {
  font-weight: 600;
  font-size: 20px;
  color: #111111;
}
#root .illustrator-list-root .player-container .description .title-container .artist {
  font-size: 14px;
  color: #111111;
}
#root .illustrator-list-root .player-container .description .detail-container {
  margin-top: 72px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  -moz-column-gap: 24px;
       column-gap: 24px;
}
@media (max-width: 560px) {
  #root .illustrator-list-root .player-container .description .detail-container {
    grid-template-columns: 1fr;
  }
}
#root .illustrator-list-root .player-container .description .detail-container .item {
  display: flex;
  gap: 25px;
  font-size: 14px;
}
#root .illustrator-list-root .player-container .description .detail-container .item .label {
  color: #767676;
  width: 80px;
}
#root .illustrator-list-root .player-container .description .detail-container .item .value {
  color: #111111;
}
#root .illustrator-list-root .player-container .description .resolution-container {
  background-color: #dbdbdb;
  padding: 10px 10px 20px;
  margin-top: 40px;
}
#root .illustrator-list-root .player-container .description .resolution-container .title {
  color: #767676;
  font-size: 13px;
  margin-bottom: 12px;
}
#root .illustrator-list-root .player-container .description .resolution-container .item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 6px;
}
#root .illustrator-list-root .player-container .description .resolution-container .item:last-child {
  margin-bottom: 0;
}
#root .illustrator-list-root .player-container .description .resolution-container .item .label {
  color: #111111;
}
#root .illustrator-list-root .player-container .description .resolution-container .item .value {
  color: #5c55fa;
}
#root .illustrator-list-root .player-container .description .bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px;
}
#root .illustrator-list-root .player-container .description .bottom-container .tag-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
#root .illustrator-list-root .player-container .description .bottom-container .tag-container .title {
  font-size: 14px;
  color: #767676;
}
#root .illustrator-list-root .player-container .description .bottom-container .tag-container .item-list {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
}
#root .illustrator-list-root .player-container .description .bottom-container .tag-container .item-list .item {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  color: #767676;
  padding: 7px 12px;
  border-radius: 16px;
  font-size: 14px;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container {
  position: relative;
  display: flex;
  gap: 20px;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .btn {
  cursor: pointer;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .download-dropdown {
  position: absolute;
  bottom: -90px;
  left: -50%;
  color: #111111;
  font-size: 14px;
  width: 123px;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .download-dropdown::before {
  position: absolute;
  top: -6px;
  left: calc(50% - 3px);
  display: block;
  content: " ";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .download-dropdown .down-btn {
  display: block;
  padding: 12px 16px;
  color: #111111;
  background-color: #ffffff;
  cursor: pointer;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .download-dropdown .down-btn:first-child {
  border-radius: 4px 4px 0 0;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .download-dropdown .down-btn:last-child {
  border-radius: 0 0 4px 4px;
}
#root .illustrator-list-root .player-container .description .bottom-container .option-container .download-dropdown .down-btn:hover {
  background-color: #e8e7fb;
}
#root .illustrator-list-root .player-container .description .close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
}/*# sourceMappingURL=illustrator.list.styles.css.map */